import React from 'react';
import Picker from './'

const Period = ( { onChange }) => {
    const options = Object.keys([...Array(9).keys()]).map(e => {

        const startYear = 1864 + e * 20;
        const endYear = startYear +  19;
        const periodNumber = parseInt(e) + 1;
        return {
            label: `Period ${periodNumber} ${startYear}-${endYear}`,
            value: periodNumber,
        }

    })
    return <>
        <Picker initialValue={8} options={options} onChange={onChange} />
    </>;
}

export default Period;