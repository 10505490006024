import { TWENTY_FOUR_MOUNTAIN, FORWARD_LUO_SHU_PATTERN, DIRECTION_PALACE, DIRECTION, SOLAR_TERM } from '../constants';
import { gregorianToBazi } from '../FourPillar'
import { differenceInCalendarMonths } from 'date-fns';

export const createFlyingstarChart = (facing, period) => {

    const basePlate = createBasePlate(period);
    const facingMountain = TWENTY_FOUR_MOUNTAIN.find(e => e.facing === facing.toLowerCase());
    const sittingDirection = facingMountain.sitting.slice(0, -1);
    const facingDirection = facingMountain.facing.slice(0, -1);
    const sittingStar = basePlate[DIRECTION_PALACE[sittingDirection]]
    const facingStar = basePlate[DIRECTION_PALACE[facingDirection]]
    const sittingPlate = createPlate(sittingStar, facingMountain.sitting.charAt(facingMountain.sitting.length-1), period);
    const facingPlate = createPlate(facingStar, facingMountain.facing.charAt(facingMountain.facing.length-1), period);
    
    return basePlate.map((baseNumber, i) => ({ baseNumber, mountainNumber: sittingPlate[i], waterNumber: facingPlate[i], direction: DIRECTION[i] }))
}

export const getStarFlight = (star, sector, period) => {
   
    const compareStar = star === 5 ? period : star;
    const generate = type => generateSequence(1, 9, type ? star - 1 : 9 - star, type);
    if([1, 3, 7, 9].includes(compareStar)) {
        return generate(sector === '1');
    } else {
        return generate(!(sector === '1'));
    } 
}

export const generateSequence = (sequenceStart, length, startIndex, ascending) => {
    const sequence = ascending ? [...Array(length).keys()].map(e => sequenceStart + e) : 
    [...Array(length).keys()].map(e => sequenceStart + e).slice().reverse();

    return sequence.slice(startIndex).concat(sequence.slice(0, startIndex))
}

const createPlate = (star, sector, period) => {
    return arrangePattern(getStarFlight(star, sector, period), FORWARD_LUO_SHU_PATTERN);
}

export const createBasePlate = (period) => {
    const basePlate = [...Array(9).keys()].map((e, i) => (period + i) > 9 ? (period + i) % 9 : (period + i))
    return arrangePattern(basePlate, FORWARD_LUO_SHU_PATTERN)
}

export const arrangePattern = (data, pattern) => {
    return data.map((e, i) => data[pattern.findIndex(e => i === e)])
}

export const getPeriodByYear = (year) => Math.floor((year - 1864) / 20) + 1

export const calculateYearPeriod = date => {
    const bazi = gregorianToBazi(date.getFullYear(), date.getMonth() + 1 , date.getDate(), date.getHours());
    const year = ["小寒", "大寒"].find(e => e === bazi.solarTerm) ? date.getFullYear() - 1 : date.getFullYear();
    return getPeriodByYear(year);
}

export const getAnnualStar = date => {
    const bazi = gregorianToBazi(date.getFullYear(), date.getMonth() + 1 , date.getDate(), date.getHours());
    const year = ["小寒", "大寒", "冬至"].find(e => e === bazi.solarTerm) && [0, 1].includes(date.getMonth()) ? date.getFullYear() - 1 : date.getFullYear();
    const diff = year - 1900;
    const YEAR1900_STAR = 1
    const move = (YEAR1900_STAR - diff % 9) < 0 ? 9 + (YEAR1900_STAR - diff % 9) : (YEAR1900_STAR - diff % 9);
    return move === 0 ? 9 : move;
}

export const getMonthlyStar = date => {
    const numericMonth = date.getMonth() + 1;
    const bazi = gregorianToBazi(date.getFullYear(), numericMonth, date.getDate(), date.getHours());
    const termIndex = SOLAR_TERM.findIndex(e => e === bazi.solarTerm);
    const termBorderIndex = Math.floor(numericMonth * 2 - 1) - 1;
    
    let caliberate = 0;
    if((termIndex < termBorderIndex || termBorderIndex === 0) && (termIndex === 23)) {
        caliberate = -1;
    }
    
    const jan1900 = new Date(1900, 0, 10, 11);
    const diff = differenceInCalendarMonths(date, jan1900) + caliberate
    const YEAR1900JAN_STAR = 9
    const move = (YEAR1900JAN_STAR - diff % 9) < 0 ? 9 + (YEAR1900JAN_STAR - diff % 9) : (YEAR1900JAN_STAR - diff % 9);
    return move === 0 ? 9 : move;
}

export const calculatePersonalGuaByYear = (year, gender) => {
    const last2Digit = year.toString().substr(2);
    let num = last2Digit.split('');
    while(num.length > 1) {
        num = (Number(num[0]) + Number(num[1])).toString().split('')
    }

    if(year >= 2000) {
        const addNumber = gender === 'M' ? -9 : 6;
        num = (Number(num[0]) + addNumber)
        num = Math.abs(num === 0 ? 9 : num).toString().split('');
       
    } else {
        const addNumber = gender === 'M' ? -10 : 5;
        num = Math.abs((Number(num[0]) + addNumber)).toString().split('');
    }
    while(num.length > 1) {
        num = (Number(num[0]) + Number(num[1])).toString().split('')
    }
    
    return Number(num[0]) === 5 ? gender === 'M' ? 2 : 8
    : Number(num[0])
}