import React from 'react';
import Picker from './'
import { TWENTY_FOUR_MOUNTAIN } from '../../cm-lib/constants'

const Period = ( { onChange }) => {
    const options = TWENTY_FOUR_MOUNTAIN.map(e => {

        const endDegree = Number(e.start) + Number(14.9);
        return {
            label: `Facing ${e.facing.toUpperCase()} ${e.start}-${endDegree} ${e.name}`,
            value: e.facing,
        }
    })
    return <>
        <Picker options={options} onChange={onChange} />
    </>;
}

export default Period;
