import React from 'react';
import { Text, ResponsiveContext } from 'grommet'
import styled from 'styled-components'
import { Symbol } from './Styles'


const MountainStar = styled.div`
    position absolute;
    left: 20px;
    top: 30px;
    width: 20px;
    text-align: center;
`

const WaterStar = styled.div`
    position absolute;
    right: 20px;
    top: 30px;
    width: 20px;
    text-align: center;
`

const BaseStar = styled.div`
    position absolute;
    left: 50%;
    top: 80px;

    width: 20px;
    text-align: center;
    margin-left: -10px;
`
const FlyingStarSquare = ({ index, ...props }) => {
    const cardinal = ["SE", "S", "SW", "E", "", "W", "NE", "N", "NW"]
    const size = React.useContext(ResponsiveContext);
    return (
        <>
            <MountainStar><Text color="white" size="xlarge">{ props.mountainNumber }</Text></MountainStar>
            <WaterStar><Text color="white" size="xlarge">{ props.waterNumber }</Text></WaterStar>
            
            <BaseStar><Text color="white" size="xlarge"> { props.baseNumber }</Text></BaseStar>
            <Symbol index={8}>
                <Text color="white" size={size}>{ cardinal[index] }</Text>
            </Symbol>
        </>
    );
}
export default FlyingStarSquare;