import DongGong from './DongGong.json'
import { ALAMAC_DAY_HOUR, EB, TWELVE_OFFICER, TWENTY_EIGHT_CONSTELLATION, SIXTY_PILLAR, HS } from '../constants'
import { differenceInCalendarDays } from 'date-fns'


export const get12Officer = (monthBranch, dayBranch) => {
    return TWELVE_OFFICER[determineOfficerIndex(EB.findIndex(e => e === monthBranch), EB.findIndex(e => e === dayBranch))] 
}

export const getDongGongAdvice = (monthBranch, dayBranch) => {
    const officerOrder = EB.slice(EB.indexOf(monthBranch)).concat(EB.slice(0, EB.indexOf(monthBranch)));
    const officerIndex = determineOfficerIndex(officerOrder.indexOf(monthBranch), officerOrder.indexOf(dayBranch));
    return {
        ...DongGong[getDongGongAdviceIndex(monthBranch, officerIndex)],
        officer: TWELVE_OFFICER[officerIndex]
    }
}

export const getDongGongAdviceIndex = (monthBranch, officerIndex) => {
    
    const monthOrder = EB.slice(2).concat(EB.slice(0, 2));
    return monthOrder.indexOf(monthBranch) * 12 + officerIndex;
}

export const determineOfficerIndex = (monthBranchNumber, dayBranchNumber) => {
    var distance = dayBranchNumber - monthBranchNumber;
    if(distance < 0) {
        distance = 12 + distance;
    }
    return distance;
}

export const isYearBreaker = (yearBranch, dayBranch) => {
    return isClash(dayBranch, yearBranch)
}

export const isMonthBreaker = (monthBranch, dayBranch) => {
    return isClash(dayBranch, monthBranch)
}

export const isThreeKilling = (yearBranch, dayBranch) => {
    const lookup = {
        '申子辰': '寅午戌',
        '亥卯未': '巳酉丑',
        '寅午戌': '申子辰',
        '巳酉丑': '亥卯未'
    }
    const threeKillingBranches = Object.keys(lookup).find(e => lookup[e].indexOf(yearBranch) > -1)
    return threeKillingBranches.indexOf(dayBranch) > -1;
}

export const isFourSeperatingDay = (month, date) => {
    return !!['320', '620', '920', '1221'].find(e => e === `${month}${date}`)
}

export const isFourExtinctDay = (month, date) => {
    return !!['23', '55', '88', '117'].find(e => e === `${month}${date}`)
}

export const isClash = (branch1, branch2) => {
    const b1 = EB.indexOf(branch1);
    const b2 = EB.indexOf(branch2);
    return Math.abs(b1 - b2) === 6
}

export const getConstellation = (date) => {
    const diff = differenceInCalendarDays(date, new Date(2007, 1, 1))
    return TWENTY_EIGHT_CONSTELLATION[diff % 28];
}

export const isNoWealthDay = (yearPillar, dayPillar) => {
    const lookup = ['寅','卯','巳','午','巳','午','申','酉','亥','子']
    const emptyBranch = getEmptinessBranch(yearPillar)
    const emptyStem = lookup.reduce((a, n, i) => {
        if(emptyBranch.includes(n)) {
            a.push(HS[i])
        }
        return a;
    }, [])

    const distanceToGui = 9 - HS.findIndex(e => e === yearPillar.substr(0, 1));
    const pillarIndex = SIXTY_PILLAR.findIndex(e => e === yearPillar) + distanceToGui
    
    
    return SIXTY_PILLAR.slice(pillarIndex - 9, pillarIndex + distanceToGui)
    .filter(e => emptyStem.includes(e.substr(0, 1))).includes(dayPillar);
}

export const getEmptinessBranch = (pillar) => {
    const iterate = [...SIXTY_PILLAR, ...SIXTY_PILLAR];
    const distanceToGui = 9 - HS.findIndex(e => e === pillar.substr(0, 1));
    const pillarIndex = iterate.findIndex(e => e === pillar) + distanceToGui
    const pillar1 = iterate[pillarIndex + 1];
    const pillar2 = iterate[pillarIndex + 2];
    return [pillar1.substr(1, 1), pillar2.substr(1, 1)]
}

export const getAlamacHourStar = (dayPillar) => {
    return ALAMAC_DAY_HOUR[dayPillar]
}

export const getHorseStar = (branch) => {
    const lookup = {
        '申子辰': '寅',
        '亥卯未': '巳',
        '寅午戌': '申',
        '巳酉丑': '亥'
    }
    const key = Object.keys(lookup).find(k => {
        return k.indexOf(branch) > -1
    })
    return lookup[key];
}