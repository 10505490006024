import React from "react"
import { Grommet, Box, Button, Text, Menu, Sidebar } from 'grommet';
import { grommet } from 'grommet/themes';
import '../pages/index.css'
import './i18n';
import { navigateTo } from 'gatsby-link';
import { Adsense } from '@ctrl/react-adsense';
import { Menu as MenuIcon } from 'grommet-icons'
import { navigate } from 'gatsby'

const Home = ({ location, children, max = '560px' }) => {

  const theme = {
    global: {
      colors: {
        // Overriding existing colors
        brand: '#db045a',
      }
    },
    text: {
      font: {
        family: 'roboto'
      }
    },
    button: {
      border: {
        radius: '2px',
        color: '#db045a'
      }
    }
  }

  return <Grommet full theme={theme}>
      <Box
        gridArea="header"
        direction="row"
        align="center"
        pad={{ horizontal: 'medium', vertical: 'small' }}
        background="#db045a"

      >
        <Button margin={{ right: '20px'}} onClick={() => { navigateTo('https://fengshuimechanics.com') }}>
          <Text size="large">Art Of Destiny</Text>
        </Button>
        <Box margin={{ left: 'auto'}}>
        <Menu
          label={<MenuIcon />}
          items={[
            { label: 'Saved Chart', onClick: () => { navigate('/caselist')} },
            { label: 'Life Gua', onClick: () => { navigate('/lifegua')} },
          ]}
        />
        </Box>
      </Box>
      <Box 
        align="center"
        justify="center"
        pad="small"
      >
        <Box 
          pad={{
            bottom: '64px'
          }}
          fill="horizontal"
          width={{
          min: '360px',
          max
        }} >

          { children }
        </Box>
        <Adsense
            client="ca-pub-6343289823091285"
            slot="5114483936"
          />
      </Box>
    </Grommet>
}

export default Home
