import { parseISO } from 'date-fns'

const queryString = require('query-string');

export const withDirection = arr => ["SE", "S", "SW", "E", "", "W", "NE", "N", "NW"]
.map((e, i) => ({
    ...arr[i],
    direction: e
}))

const isBrowser = typeof window !== `undefined`

export const getQueryString = () => isBrowser && queryString.parse(window && window.location.search)

export const assign = url => isBrowser && window.location.assign(url)

export const parseStringDate = (str, fallback) => !str && isNaN(parseISO(str).getTime()) ? fallback :  parseISO(str)

export const chunk = (arr, len) => {

    var chunks = [],
        i = 0,
        n = arr.length;
  
    while (i < n) {
      chunks.push(arr.slice(i, i += len));
    }
  
    return chunks;
  }

  export const setStorage = (key, value) => {
    try {
      const localStorage = isBrowser && window.localStorage;
      localStorage && localStorage.setItem(key, JSON.stringify(value));
    }
    catch(e) {
      alert(e)
    }
  }

  export const getStorage = (key, defaultValue) => {
    const localStorage = isBrowser && window.localStorage;
    const returnValue = JSON.parse(localStorage.getItem(key))
    return returnValue ? returnValue : defaultValue
  }
  

  export const breakpoints = {
    sm: 30,
    md: 30,
    lg: 45,
    xl: 60
  };
  
  export const mediaQueries = key => {
    return style => `@media (min-width: ${breakpoints[key]}em) { ${style} }`
  }
  