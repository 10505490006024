import LunarCalendar from 'lunar-calendar';
import { parseISO, differenceInCalendarDays } from 'date-fns';
import { isEven } from '../util/number'
import { HS, EB, SIXTY_PILLAR, SOLAR_TERM } from '../constants/';
import SolarTerm from './SolarTerm.json'
import ClassicalStars from './ClassicalStars.json'
import { getEmptinessBranch } from '../DateSelection'
import { HIDDEN_STEM } from './constants';
import NaYin from './NaYin.json'


export const getHiddenStem = branch => HIDDEN_STEM[branch];

export const getSolarTerm = (year, month, day, hour, min) => {
    
    const paddedMonth = `${month}`.padStart(2, '0');
    const paddedDay = `${day}`.padStart(2, '0');
    const h = `${hour}`.padStart(2, '0');
    const m = `${min}`.padStart(2, '0');

    const yearMonthString = `${year}${paddedMonth}${paddedDay}T${h}:${m}`;
    
    const solarTerm = SolarTerm.slice().reverse().find(e => yearMonthString > e.d);
    const NextSolarTerm = SolarTerm.find(e => e.d > solarTerm.d);

    const principalSolarTerms = SOLAR_TERM.filter((e, i) => i % 2 === 0)
    const principalTermForDate = SolarTerm.slice().reverse().find(e => yearMonthString > e.d  && principalSolarTerms.includes(e.s))
    const ptIndex = principalSolarTerms.findIndex(e => e === principalTermForDate.s);
    const referencePT = principalSolarTerms.slice(ptIndex).concat(principalSolarTerms.slice(0, ptIndex))
    const nextPrincipalTermForDate = SolarTerm.find(e => yearMonthString < e.d && e.s === referencePT[1]);

    return { 
        solarTerm: solarTerm.s,
        transitionDay: solarTerm.d.substr(0, 8) === yearMonthString.substr(0, 8),
        dayToNextTerm: differenceInCalendarDays(parseISO(NextSolarTerm.d), parseISO(yearMonthString)) + 1,
        dayToNextPrincipalTerm: differenceInCalendarDays(parseISO(nextPrincipalTermForDate.d), parseISO(yearMonthString)),
        dayToPreviousPrincipalTerm: differenceInCalendarDays(parseISO(yearMonthString), parseISO(principalTermForDate.d)),
    }
}

export const getPillarSequence = (pillar, length, direction = true) => {
    
    const concatPillar = [...Array(Math.ceil(length/60 + 1))].reduce((a, n) => {
        return [
            ...a,
            ...SIXTY_PILLAR
        ]
    }, [])
    const useConcatPillar = direction ? concatPillar : concatPillar.slice().reverse()
    const pillarIndex = useConcatPillar.indexOf(pillar)
    return useConcatPillar.slice(pillarIndex, pillarIndex + length)
}

const deriveStars = (year, month, day, hour) => {
    const dayBranch = day.ganzhi.substr(1, 1);
    const dayStem = day.ganzhi.substr(0, 1);
    return {
        classical: {
            ...ClassicalStars[dayBranch],
            ...ClassicalStars[dayStem],
            '空亡': getEmptinessBranch(day.ganzhi).join('')  
        },
        blindman: {
            '空亡': getEmptinessBranch(day.ganzhi).join('')  
        },
    }
}

const _gregorianToBazi = (year, month, day, hour = 12, min = 0, gender) => {
    const monthIndex = month - 1;
    const date = new Date(year, monthIndex, day);
    const result = LunarCalendar.calendar(date.getFullYear(), date.getMonth() + 1, date.getDate());
    const found = result.monthData.findIndex(e => e.year === Number(year) && e.month === Number(month) && e.day === Number(day))
    const { GanZhiYear, GanZhiMonth, GanZhiDay } = result.monthData[found];
    const GanZhiHour = calculateHour(GanZhiDay.substr(0, 1), hour);
    return found > -1 && {
        lunarMonthNumber: result.monthData[found].lunarMonth - 1,
        ...result.monthData[found],
        GanZhiYear, 
        GanZhiMonth, 
        GanZhiDay,
        GanZhiHour
    };
}

export const dateObjectToBazi = (date, gender) => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const dateOfMonth = date.getDate();
    const hour = date.getHours();
    const min = date.getMinutes();
    return gregorianToBazi(year, month, dateOfMonth, hour, min, gender )
}

export const gregorianToBazi = (year, month, day, hour, min = 0, gender) => {

    const westernAge = new Date(Date.now()).getFullYear() - year;
    const bazi = _gregorianToBazi(year, month, day, hour, min, gender);
    if(bazi) {
        let { GanZhiYear, GanZhiMonth, GanZhiDay, GanZhiHour } = bazi;
        const termInfo = getSolarTerm(year, month, day, hour, min);
        const forward = (gender === 'M' && isEven(SIXTY_PILLAR.indexOf(GanZhiYear))) || (gender === 'F' && !isEven(SIXTY_PILLAR.indexOf(GanZhiYear)))
        const startAgeQuotient = forward ? Math.floor(termInfo.dayToNextPrincipalTerm / 3) : Math.floor(termInfo.dayToPreviousPrincipalTerm / 3);
        const remainder = forward ? termInfo.dayToNextPrincipalTerm % 3 : termInfo.dayToPreviousPrincipalTerm % 3;
        const startWesternAge = remainder === 2 ? startAgeQuotient + 1 : startAgeQuotient;
        
        const tenYearCycle = getPillarSequence(GanZhiMonth, 11, forward).slice(1, 10);
        const hundredYear = ["小寒", "大寒"].includes(termInfo.solarTerm) 
        ? getPillarSequence(GanZhiYear, 101).slice(1, 100) : getPillarSequence(GanZhiYear, 100);
        const monthBazi = _gregorianToBazi(year, month, 16)
        const monthCycle = getPillarSequence(monthBazi.GanZhiMonth, 1200);

        GanZhiYear = convertGanZhi(GanZhiYear);
        GanZhiMonth = convertGanZhi(GanZhiMonth);
        GanZhiDay = convertGanZhi(GanZhiDay);
        GanZhiHour = convertGanZhi(GanZhiHour);

        const activeLuckPillarIndex =  westernAge >= startWesternAge ? 
        Math.floor((westernAge - startWesternAge) / 10) : -1;

        return ({
            westernAge,
            startWesternAge,
            currentYearPillar: hundredYear[westernAge],
            currentLuckPillar: tenYearCycle[activeLuckPillarIndex],
            westernYear: year,
            westernMonth: month,
            westernDay: day,
            ...termInfo,
            ...bazi,
            GanZhiYear, 
            GanZhiMonth, 
            GanZhiDay, 
            GanZhiHour,
            startAge: Math.round(Math.abs(startWesternAge)),
            tenYearCycle: tenYearCycle,
            hundredYear: hundredYear.map((e, i) => ({ 
                pillar: e,
                year: year + i
            })),
            monthCycle,
            stars: deriveStars(GanZhiYear, GanZhiMonth, GanZhiDay, GanZhiHour),
            dayNayin: NaYin[GanZhiDay.ganzhi]
        });
    } else {
        return "";
    }
}

const convertGanZhi = (ganzhi) => {
    const ganIndex = HS.indexOf(ganzhi.substr(0, 1));
    const zhiIndex = EB.indexOf(ganzhi.substr(1, 1));
    return {ganzhi: ganzhi, ganIndex, zhiIndex};
}

export const dateToBazi = (date, gender) => {
    return gregorianToBazi(date.getFullYear(), date.getMonth() + 1, date.getDate(), date.getHours(), date.getMinutes(), gender)
}

export const calculateHour = (dayGan, hour) => {
    const hourBranchIndex = Math.floor(((hour + 1)/2) % 12);

    
    const dayStemIndex = HS.findIndex(e => e === dayGan);

    const hourStemIndex = calculateHourStem(dayStemIndex + 1, hourBranchIndex + 1, hour);
    const gan = HS[hourStemIndex];
    const zhi = EB[hourBranchIndex];

    return gan + zhi;
}

const  calculateHourStem = (dayStemNumber, hourBranchNumber, hour) => {
    if(hour === 23) {
        dayStemNumber = dayStemNumber + 1;
    }
    var hourStemIndex =(dayStemNumber * 2) + (hourBranchNumber - 2);
    return (hourStemIndex > 10) ? ((hourStemIndex - 1) % 10) : hourStemIndex - 1;
}