import styled from 'styled-components'

export const Symbol = styled.div`
    position absolute;
    ${props => [0, 3, 6].includes(props.index) ? 'left: 10px;' : '' }
    ${props => [0, 3, 6].includes(props.index) ? 'left: 10px;' : '' }
    ${props => [2, 5, 8].includes(props.index) ? 'right: 10px;' : '' }
    ${props => [6, 7, 8].includes(props.index) ? 'bottom: 10px;' : '' }
    ${props => [1, 4, 7].includes(props.index) ? 'left: 50%;' : '' }
    ${props => [1, 4, 7].includes(props.index) ? 'margin-left: -10px;' : '' }
    ${props => [3].includes(props.index) ? 'top: 50%; margin-top: -20px;' : '' }
    width: ${props => props.width ? props.width : '20px'};
    ${props => props.width ? 'left: 0;': ''};
    text-align: center;
`